var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{staticClass:"primary"},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"flex-column",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6","lg":"4","xl":"3"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',{staticClass:"z-heading-h4"},[_vm._v(" Cambia password ")]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('z-login-field',{staticClass:"pb-3",attrs:{"type":"password","label":"Nuova password","width":null,"rules":[_vm.required, _vm.passwordLength, _vm.passwordIsPassword],"outlined":""},model:{value:(_vm.passwords.password.value),callback:function ($$v) {_vm.$set(_vm.passwords.password, "value", $$v)},expression:"passwords.password.value"}}),_c('z-login-field',{attrs:{"label":"Conferma password","type":"password","rules":[
                  _vm.required,
                  _vm.passwordLength,
                  _vm.passwordCheck(
                    _vm.passwords.password.value,
                    _vm.passwords.confirmPassword.value
                  ),
                ],"outlined":"","width":null},model:{value:(_vm.passwords.confirmPassword.value),callback:function ($$v) {_vm.$set(_vm.passwords.confirmPassword, "value", $$v)},expression:"passwords.confirmPassword.value"}}),_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('z-btn',{attrs:{"type":"text-neutral-hc"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({ name: 'login' })}}},[_vm._v(" Annulla ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('z-btn',{attrs:{"color":"primary darken-2"},on:{"click":_vm.onSubmit}},[_vm._v(" Salva ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }