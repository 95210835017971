
import { Component, Vue } from "vue-property-decorator";
import { authenticate } from "@/services/api/authenticate.service";
import { ResetPasswordRequest } from "@/models/utils/reset-password-request.interface";
import {
  CheckTokenRequest,
  TokenType,
} from "@/models/utils/check-token-request.interface";
import {
  required,
  passwordLength,
  passwordIsPassword,
  passwordCheck,
} from "@/helpers/rules";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
const snackbarModule = getModule(SnackbarModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component
export default class ResetPassword extends Vue {
  private valid = true;
  private required = required;
  private passwordLength = passwordLength;
  private passwordIsPassword = passwordIsPassword;
  private passwordCheck = passwordCheck;

  private passwords = {
    password: {
      show: false,
      value: "",
    },
    confirmPassword: {
      show: false,
      value: "",
    },
  };
  private pwdRequest = {} as ResetPasswordRequest;
  private tokenRequest = {} as CheckTokenRequest;

  async created(): Promise<void> {
    this.$set(this.tokenRequest, "token", this.$route.query["q"]);
    this.$set(this.tokenRequest, "emailTokenType", TokenType.PASSWORD);
    overlayModule.showOverlay();
    const check = await authenticate.checkToken(this.tokenRequest);
    if (check.data.notValid) {
      snackbarModule.showSnackbar({
        message: "Token non valido",
        type: "error",
      });
      setTimeout(() => snackbarModule.hideSnackbar(), 2000);
      this.$router.push({ name: "login" });
    }
  }

  async onSubmit(): Promise<void> {
    const isValid = (
      this.$refs.form as Vue & { validate: () => boolean }
    ).validate();
    this.valid = isValid;
    if (isValid) {
      overlayModule.showOverlay();
      this.$set(this.pwdRequest, "password", this.passwords.password.value);
      this.$set(
        this.pwdRequest,
        "confirmPassword",
        this.passwords.confirmPassword.value
      );
      this.$set(this.pwdRequest, "token", this.$route.query["q"]);
      await authenticate.resetPassword(this.pwdRequest);
      snackbarModule.showSnackbar({
        message: "Salvataggio avvenuto con successo",
        type: "success",
      });
      this.$router.push({ name: "login" });
    }
  }
}
